.navbar .nav-item .dropdown-menu {
  display: none;
}
.navbar .nav-item:hover .dropdown-menu {
  display: block;
  background-color: #002242;
  text-align: left;
  font-family: Nunito;
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px!important;
  letter-spacing: 0.04em;
}
.navbar .nav-item .dropdown-menu {
  margin-top: 0px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.navbar a,
.navbar a:link,
.navbar a:hover,
.navbar a:visited,
.navbar a:active,
.navbar a:focus{
  background-color: transparent;
  text-align: left;
  font-family: Nunito;
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px!important;
  letter-spacing: 0.04em;
  display: inline-block;
}
@media (max-width: 991px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
    background-color: #002242;
    text-align: left;
    font-family: Nunito;
    text-decoration: none;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px!important;
    letter-spacing: 0.04em;
  }
  .navbar .nav-item .dropdown-menu {
    margin-top: 0px;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .navbar a,
  .navbar a:link,
  .navbar a:hover,
  .navbar a:visited,
  .navbar a:active,
  .navbar a:focus{
    background-color: transparent;
    text-align: left;
    font-family: Nunito;
    text-decoration: none;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px!important;
    letter-spacing: 0.04em;
    display: inline-block;
  }
  .fixed-top .navbar-collapse {
    max-height: calc(100vh - 4.5em);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
