@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
:root {
  --darkblue: #002242;
  --pink: #ff0066;
  --skin: #fff3db;
  --yellow: #ffd600;
  --white: #ffffff;
  --hover-yellow: #fad20d;
  --hover-pink: #e6005c;
  --text: #3b3b53;
}
.navbar {
  background-color: var(--darkblue) !important;
  width: 100%;
  z-index: 1;
}
.navbar-brand {
  position: absolute;
  width: 78px;
  height: 33px;
  left: 72px;
  top: 17px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: var(--yellow) !important;
}
.navbar-toggler.hamburger {
  border-color: var(--darkblue);
  margin-left: 20px;
}
.hamburger:active,
.hamburger:focus,
.hamburger:focus:active {
  background-image: none;
  outline: 0;
  box-shadow: none;
}
.navbar-collapse {
  background-color: var(--darkblue);
  padding-left: 30px;
}
.links {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #ffffff !important;
  text-transform: capitalize !important;
  margin-top: auto;
  margin-right: 15px;
}
.nav-item a:hover {
  color: var(--yellow) !important;
}
.buy_btn {
  width: 150px;
  height: 40px;
  background: var(--yellow) !important;
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: bolder !important;
  font-size: 14px;
  line-height: 40px;
  text-align: center !important;
  letter-spacing: 0.04em;
  color: var(--darkblue) !important;
  margin-right: 30px;
}
.buy_btn:hover {
  background: var(--hover-yellow) !important;
}
.buy_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--text);
}
.navbar .buy_btn a:hover[href="https://rzp.io/l/huesofyou"]
{
  color: inherit !important;
}
@media (max-width: 991px) {
  .buy_btn {
    text-align: center !important;
  }
  .navbar-brand {
    font-size: 1.5rem !important;
  }
}
.helpline {
  color: var(--pink);
  margin-top: 85vh;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-transform: capitalize !important;
  z-index: 1000;
  float: right;
  position: fixed;
  right: 10px;
  border: none;
  background: transparent;
}
.sos {
  width: 60px;
  height: 60px;
}
button:focus,
input[type="button"]:focus {
  outline: none;
}
button:active,
input[type="button"]:active {
  outline: none;
}
.helpline_heading {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 19px;
  letter-spacing: 0.04em;
}
.numbers {
  font-family: Nunito;
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  color: var(--text);
  text-align: left;
}

/*----------------------------------------------------
Collaborate with us
-----------------------------------------------------*/
.collab_us {
  background: #fff3db;
  background-image: url("./images/collaboratebg.png");
  background-repeat: no-repeat;
}
.collabus_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  padding-top: 90px;
  padding-bottom: 28px;
}
.collabus_para {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--text);
}
.btn-collaborate {
  width: 188px;
  height: 40px;
  left: 150px;
  top: 532px;
  background-color: var(--pink);
  border-radius: 4px;
  color: white;
  margin-bottom: 90px;
  margin-top: 24px;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
}
.btn-collaborate:hover {
  background-color: var(--hover-pink);
  color: #ffffff;
}

/*-----------------------------------------------------
EVENTS
-------------------------------------------------------*/
/*head part*/

.head_div {
  padding-top: 130px;
}
.events_heading {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  text-align: center;
  margin-bottom: 28px;
}
.events_para {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
}
.search_heading {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: var(--text);
}
.search_events {
  height: 40px;
  border: 1px solid var(--text);
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  margin-top: 20px;
}
/*-----------------------------------------------------
Popular Events
------------------------------------------------------*/
.popularevents_div {
  padding-bottom: 130px;
  background: #fff3db;
  height: auto;
  background-image: url("./images/eventsbg2.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.popularevents_heading {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-top: 90px;
  text-align: center;
  margin-bottom: 28px;
}
.popular_events_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--text);
  margin-bottom: 48px;
}
.popularevent_card {
  border: 1.5px solid var(--text);
  box-sizing: border-box;
  border-radius: 4px;
}
.events_cards {
  border: 1px solid var(--text);
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 48px;
}
.card-title {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 38px;
  color: var(--text);
}
.card-subtitle {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: var(--text);
  opacity: 0.85;
}
.card-text {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: var(--text);
}
.card-link {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--pink);
  text-decoration: none;
}
.card-title-events {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: var(--text);
}
.card-subtitle-events {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: var(--text);
  opacity: 0.85;
}
.card-text-events {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: var(--text);
  opacity: 0.8;
}
.card-link-events {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--pink);
  text-decoration: none;
  padding-bottom: 36px;
  cursor: pointer;
}

/*----------------------------------------------------
EVENTS LIST
------------------------------------------------------*/
.eventslist_div {
  margin-bottom: 130px;
  background-image: url("./images/eventsbg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.eventslist_heading {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-top: 60px;
}
.card-img-top {
  border: 1.5px solid var(--text);
  box-sizing: border-box;
  border-radius: 4px;
}
.hotmeve {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.04em;
  color: var(--pink);
  text-decoration: none;
}
.eventslist_subheading {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 48px;
}
.events_buttons {
  color: black;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--pink);
}
/*===========================================
DELIVERED WORSHOPS IN
===============================================*/
.logodw {
  width: 70% !important;
  height: 80% !important;
}
.dw_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-top: 90px;
  margin-bottom: 40px;
}
.dw {
  margin-bottom: 60px;
}
/*-------------------------------------------------------
Help Yourself head part
----------------------------------------------------------*/
.helpyourselfhead_div {
  background-image: url("./images/helpyourselfbg1.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.helpyourself_img {
  width: 200px;
  height: 220px;
}
.helpyourself_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-top: 150px;
  margin-bottom: 50px;
}
.hy_subheading {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: var(--text);
  margin-top: 20px;
}
.hy_headings {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: var(--text);
}
.see_more {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--pink);
  text-decoration: none;
}
.list_link {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  color: var(--pink);
}
/*--------------------------------------
What our participants have to say
----------------------------------------*/
.participants_div {
  background-image: url("./images/eventsbg2.png");
  padding-bottom: 90px;
}
.howhuelet {
  background-image: url("./images/eventsbg2.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.people_heading {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-bottom: 48px;
  margin-top: 40px;
}
.peoplecard_div {
  background-color: var(--darkblue);
}
.card-text-people {
  font-family: Nunito;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #ffffff;
  margin-top: 70px;
}
.card-text-people1 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 5px;
  padding-top: 50px;
}
.card-text-people2 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 70px;
}
/*--------------------------------------
FAQS
-----------------------------------------*/
.faqs_div {
  background-color: #fff3db;
  background-image: url("./images/faqbg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.faqs_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  padding-top: 60px;
  padding-bottom: 58px;
}
.accordion-toggle {
  color: white !important;
  background-color: var(--darkblue) !important;
  border-radius: 4px;
  height: 110px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
}
.accordion-toggle.collapsed {
  background-color: white !important;
  color: black !important;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.acc_items {
  margin-bottom: 40px;
}
.answer_faq {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  text-align: left;
}
/*----------------------------------------------
Huesletters card
-----------------------------------------------*/
.huesletters_div_outer {
  background-image: url("./images/Grass.png");
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: cover;
}
.huesletters_div {
  padding-bottom: 80px;
}
.huesletters_card_div {
  padding-top: 200px;
}
.huesletters_card {
  width: 800px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-left: 20px;
  padding-right: 20px;
}
.huesletters_title_card {
  font-family: Rubik;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 65px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #18191f;
  padding-top: 18px;
}
.huesletters_card_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #18191f;
  padding-bottom: 18px;
  text-align: justify;
}
.btn-read_huesletters {
  width: 167px;
  height: 40px;
  background: var(--pink);
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  text-decoration: none;
  vertical-align: middle;
}
.btn-read_huesletters:hover {
  background-color: var(--hover-pink);
  color: #ffffff;
}
.readall {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--pink);
}
.readall_link {
  text-align: center;
  margin-top: 30px;
}
.btn-subscribe_huesletters {
  width: 167px;
  height: 40px;
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.04em;
  color: var(--pink);
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid var(--pink);
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 12px;
}
.btn-subscribe_huesletters:hover {
  background-color: #ffffff;
  color: var(--hover-pink);
  border: 1px solid var(--hover-pink);
}
.hl_buttons {
  display: flex;
  flex-direction: row;
}
/*----------------------------------
Selftalk
--------------------------------------*/
.selftalk_div {
  background-color: #fff3db;
  padding-bottom: 90px;
  background-image: url("./images/stbg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.selftalk_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #18191f;
  text-align: center;
  padding-top: 120px;
  padding-bottom: 50px;
}
.selftalk_img {
  width: 80%;
  height: 90%;
  border-radius: 8px;
  vertical-align: middle;
}
.selftalk_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: var(--text);
  text-align: justify;
}
/*-------------------------------------
Negative thoughts
----------------------------------------*/
.nt {
  background-image: url("./images/nt.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.negative_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #18191f;
  text-align: center;
  margin-top: 90px;
  margin-bottom: 50px;
}
.nt_img {
  border-radius: 8px;
}
.nt_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #18191f;
  margin-top: 20px;
}
.btn-nt-getstarted {
  width: 166px;
  height: 40px;
  border: 1px solid var(--pink);
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.04em;
  color: var(--pink);
  text-decoration: none;
  margin-top: 20px;
}
.btn-nt-getstarted:hover {
  border: 1px solid var(--hover-pink);
  color: var(--hover-pink);
  background-color: #ffffff;
}
/*-----------------------------
Happy
-------------------------------*/
.happy_div {
  background-color: var(--darkblue);
  padding-top: 90px;
  padding-bottom: 90px;
}
.happy {
  background-image: url("./images/happy.png");
}
.happy_title {
  padding-top: 145px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #18191f;
}
.happy_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #18191f;
  padding-bottom: 120px;
}
/*---------------------------------
okay
-----------------------------------*/
.okay_div {
  background-color: #fff3db;
  background-image: url("./images/okaybg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.okay_card {
  margin-top: 90px;
  margin-bottom: 90px;
}
.okay_text {
  background-color: var(--darkblue);
}
.okay_title {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  text-align: right;
  color: #ffffff;
  margin-right: 60px;
}
.okay_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  text-align: right;
  color: #ffffff;
  opacity: 0.9;
  margin-top: 40px;
  margin-right: 60px;
}
/*---------------------------------
Breathing Method
------------------------------------*/
.bm {
  background-image: url("./images/bmbg.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.bm_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #18191f;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 40px;
}
.bm_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #18191f;
}
.bm_img {
  border-radius: 8px;
}
.btn-bm-getstarted {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.04em;
  color: var(--pink);
  border: 1px solid var(--pink);
  box-sizing: border-box;
  border-radius: 4px;
  width: 166px;
  height: 40px;
  text-decoration: none;
  margin-top: 40px;
  margin-bottom: 50px;
}
.btn-bm-getstarted:hover {
  color: var(--hover-pink);
  border: 1px solid var(--hover-pink);
}
.bm_desc_div {
  margin-top: auto;
  margin-bottom: auto;
}
.bm_row {
  padding-bottom: 60px;
}
/*-------------------------------------------------
Self care
----------------------------------------------------
*/
.selfcare_div_outer {
  background-image: url("./images/selfcare.png");
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: cover;
}
.selfcare_div {
  padding-bottom: 80px;
}
.selfcare_card_div {
  padding-top: 100px;
}
.selfcare_card {
  width: 700px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-left: 20px;
  padding-right: 20px;
}
.selfcare_title_card {
  font-family: Rubik;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 65px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #18191f;
  padding-top: 18px;
}
.selfcare_card_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #18191f;
  padding-bottom: 18px;
  text-align: justify;
}
.btn-selfcare {
  width: 167px;
  height: 40px;
  background: var(--pink);
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  text-decoration: none;
  vertical-align: middle;
}
.btn-selfcare:hover {
  background-color: var(--hover-pink);
  color: #ffffff;
}
/*-------------------------------------------------------
Safespace
---------------------------------------------------------*/
.safespace_div {
  background-image: url("./images/ssbg.png");
  padding-top: 45px;
  background-repeat: no-repeat;
  background-size: cover;
}
.hotmsafe {
  color: var(--pink);
  text-decoration: none;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
}
.safespace_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  padding-top: 130px;
}
.safespace_subtitle {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--text);
  margin-top: 28px;
  margin-bottom: 48px;
}
.card_sstitle {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: var(--text);
}
.card_sssubtitle {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--text);
}
.btn-ss {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--pink);
  text-decoration: none;
}
.card_safespace {
  background-color: #fff3db;
}
.safespace_para {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  padding-top: 90px;
  padding-bottom: 90px;
}
/*----------------------------------------------------
Sharestory
------------------------------------------------------*/
.sharestory_div {
  background: #fff3db;
  background-image: url("./images/sharebg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.sharestory_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  padding-top: 90px;
  padding-bottom: 28px;
}
.sharestory_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--text);
}
.btn-share {
  width: 188px;
  height: 40px;
  background-color: var(--pink);
  border-radius: 4px;
  color: white;
  margin-bottom: 90px;
  margin-top: 24px;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
}
.btn-share:hover {
  background-color: var(--hover-pink);
  color: #ffffff;
}
/*------------------------------------------------------------
Self care
-------------------------------------------------------------*/
.selfcareinfo_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  padding-top: 140px;
  margin-bottom: 40px;
}
.selfcareinfo_div {
  background-image: url("./images/selfcarebg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.selfcareinfo_card {
  background: #fff3db;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25), 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 40px;
}
.selfcareinfo_card_title {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  color: var(--text);
  margin-top: 48px;
  margin-left: 40px;
}
.selfcareinfo_card_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  color: var(--text);
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
  padding-bottom: 48px;
}
.selfcareinfo_carddiv2 {
  padding-bottom: 90px;
}
/*-----------------------------------------------------
Breathing Method Info
-------------------------------------------------------*/
.breathinginfo {
  background-image: url("./images/breathingbg.png");
  background-repeat: repeat-y;
  background-size: 100%;
}
.title_br {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: var(--text);
}
.breathing_card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25), 0px 8px 24px rgba(0, 0, 0, 0.1);
}
.breathinginfo_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-top: 140px;
  margin-bottom: 48px;
}
.breathinginfo_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: rgba(59, 59, 83, 0.8);
  vertical-align: middle;
}
.breathinginfo_desc1 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: white;
  vertical-align: middle;
}
.breathing_info_p {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  margin-top: 70px;
  color: #000000;
  margin-bottom: 70px;
}
.somatic_title1 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: var(--text);
  opacity: 0.9;
  margin-top: 40px;
  margin-left: 50px;
  margin-bottom: 20px;
}
.somatic_desc1 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: var(--text);
  opacity: 0.8;
  margin-left: 50px;
  margin-top: 20px;
  padding-bottom: 40px;
}
.somatic_title2 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: white;
  opacity: 0.9;
  margin-top: 40px;
  margin-left: 50px;
  margin-bottom: 20px;
}
.somatic_desc2 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: white;
  opacity: 0.8;
  margin-left: 50px;
  padding-bottom: 40px;
}
.somatic_card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25), 0px 8px 24px rgba(0, 0, 0, 0.1);
}
.somatic_card_outer {
  padding-bottom: 40px;
}
/*--------------------------------------------------
Negative thoughts info
----------------------------------------------------
*/

.neg_info {
  background-color: #fff3db;
  background-image: url("./images/ntbg1.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.neg_para {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: var(--text);
  margin-top: 130px;
}
.neg_stop {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-top: 30px;
}
.neg_img {
  height: 100%;
  width: 90%;
}
.neg_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: var(--text);
}
.neg_div2 {
  margin-top: 40px;
}
/*-------------------------------------------------------
Learn with hotm
---------------------------------------------------------*/
.learn-div {
  background-color: #fff3db;
  background-image: url("./images/repodiv.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.learn_rows {
  padding-bottom: 50px;
}
.learn_img {
  margin-top: 130px;
  margin-bottom: 60px;
  width: 45%;
  height: 55%;
}
.image_learn {
  width: 100%;
}
.learn_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 40px;
  font-feature-settings: "liga" off;
  color: var(--text);
}
.learn_subtitle {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: var(--text);
}
.learn_knowmore {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--pink);
  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 0;
  text-decoration: none;
}
.referenceslink {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--pink);
}
.reference {
  text-align: center;
  margin-bottom: 40px;
}
/*-----------------------------------------------------
Breaking Stigma
-------------------------------------------------------*/
.breakingstig_div_outer {
  background-image: url("./images/breakingstigma.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.breakingstig_card_div {
  padding-top: 50px;
  padding-bottom: 50px;
}
.breakingstig_card {
  width: 700px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-left: 20px;
  padding-right: 20px;
}
.breakingstig_title_card {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 65px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-top: 20px;
}
.breakingstig_card_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: var(--text);
  text-align: justify;
}
.btn-breakingstig {
  width: 167px;
  height: 40px;
  background: var(--pink);
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin-bottom: 30px;
}
.btn-breakingstig:hover {
  color: white;
  background-color: var(--hover-pink);
}
/*----------------------------------------------------
LGBTQ
------------------------------------------------------*/
.lgbtq_div {
  background-color: #fff3db;
  background-image: url("./images/lbg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.lgbtq_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-top: 130px;
}
.lgbtq_para {
  font-family: Nunito;
  font-size: 18px;
  line-height: 150%;
  color: var(--text);
  text-align: left;
  margin-top: 40px;
  margin-bottom: 90px;
}
/*-----------------------------------------------
Anxiety
--------------------------------------------------*/
.anxiety_div {
  background-image: url("./images/abg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.anxiety_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-top: 130px;
}
.anxiety_para {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: var(--text);
}
.anxiety_heading {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: var(--text);
  margin-top: 40px;
}
.anxiety_link {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--pink);
  text-decoration: none;
}
.al_div {
  margin-bottom: 90px;
}
/*----------------------------------
Breaking Stigma
------------------------------------*/
.breaking_stigma_div {
  background-color: #fff3db;
  background-image: url("./images/bsbg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.breaking_stigma_heading {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-top: 130px;
}
.breaking_stigma_sub {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--text);
  margin-top: 40px;
  margin-bottom: 40px;
}
.breaking_stigma_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: var(--text);
}
/*-----------------------------------------
OCD
-----------------------------------------------*/
.ocd_div {
  background-image: url("./images/ocdbg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.ocd_heading {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-top: 130px;
  margin-bottom: 40px;
}
.ocd_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: var(--text);
}
.ocd_disclaimer {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: var(--text);
  margin-bottom: 90px;
}
/*-----------------------------------------------------
ADHD
-------------------------------------------------------*/
.adhd_div {
  background-color: #fff3db;
  background-image: url("./images/adhdbg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.adhd_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-top: 130px;
  margin-bottom: 58px;
}
.accordion-toggle-adhd {
  color: white !important;
  background-color: var(--darkblue) !important;
  border-radius: 4px;
  height: 100px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
}
.accordion-toggle-adhd.collapsed {
  background-color: white !important;
  color: black !important;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.acc_items_adhd {
  margin-bottom: 50px;
}
.answer_adhd {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  text-align: left;
}
/*-----------------------------------------------------
HOTM REPOSITORY
-----------------------------------------------------*/
.repo_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 150px;
  color: var(--text);
  text-align: center;
}
.repo_txt {
  font-family: Nunito;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  color: var(--text);
  text-align: left;
  margin-top: 30px;
  text-align: center;
  margin-bottom: 0px;
}
.repo_txt1 {
  font-family: Nunito;
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  color: var(--text);
  text-align: left;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 0px;
}
.drop_mail {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--pink);
  border: none;
  background-color: white;
  text-decoration: underline;
}
.label_modal {
  font-family: Nunito;
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  color: var(--text);
  text-align: left;
  margin-top: 10px;
  text-align: left;
  margin-bottom: 0px;
}
.btn-submodal {
  background: var(--pink);
  border: 1px solid var(--pink);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  color: #ffffff;
  font-family: Nunito;
  margin-top: 0px;
}
.btn-hotmrepo {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
  color: var(--pink);
  margin-bottom: 50px;
  margin-top: 30px;
}
.btn-hotmrepo:hover {
  color: var(--hover-pink);
}
a {
  outline: none;
}
.menu_active {
  font-weight: bold;
  border-bottom: 3px solid var(--pink);
}
.repo_div {
  background-color: #ffffff;
  background-image: url("./images/repodiv.png");
  background-size: 100% auto;
  background-repeat: repeat;
}
.repo_card {
  background-color: var(--darkblue);
  margin-bottom: 40px;
}
.repo_img {
  width: 90%;
  height: 90%;
}
.repo_names {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 10px;
}
.repo_desc {
  font-family: Nunito;
  font-style: normal;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #ffffff;
  text-align: left;
  opacity: 0.9;
  margin-top: 20px;
}
.labels {
  font-weight: bolder !important;
  font-size: 20px !important;
  opacity: 1 !important;
  color: white !important;
}
.card-link-hotmrepo {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  text-decoration: none;
}
.searchbar_therapist {
  height: 40px;
  border: 1px solid var(--text);
  box-sizing: border-box;
  border-radius: 4px;
  -webkit-tap-highlight-color: transparent;
  margin-bottom: 40px;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
}

.search_therapist {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: var(--text);
  margin-bottom: 30px;
  margin-top: 20px;
}
/*-------------------------------------------------------
About Us
--------------------------------------------------------*/
.abouthead_div {
  width: 100%;
  height: 1024px;
  background-image: url("./images/about1.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.abt_heading {
  margin-top: 250px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-left: 120px;
  margin-bottom: 30px;
}
.abt_para1 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: var(--text);
  margin-left: 120px;
  margin-bottom: 30%;
}
.abt_para2 {
  position: relative;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: var(--text);
  text-align: center;
  display: none;
}
.sahaaraviewmore {
  display: none;
}
/*--------------------------------------
Our journey
----------------------------------------*/
.journey_div {
  background-color: white;
  padding-bottom: 0px;
}
.journey_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  padding-top: 60px;
}
/*==============================
Awards
================================*/
.award_div {
  background: var(--darkblue);
}
.award_title {
  font-family: Nunito;
  font-style: normal;
  font-weight: 900;
  font-size: 45px;
  line-height: 130%;
  margin-left: 100px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #fff3db;
}
.award_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;

  /* White */
  margin-left: 100px;
  color: #ffffff;
}
.award_img {
  width: 450px;
  height: 528px;
}
.award_title1 {
  display: none;
}
.reco_bg {
  background-color: var(--darkblue);
}
.reco_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff3db;
  margin-bottom: 35px;
  padding-top: 20px;
}
.reco_text {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
}
.reco_card {
  background-color: var(--darkblue);
  margin-bottom: 40px;
}
/*-----------------------------------------------
Workshops
-------------------------------------------------*/
.workshops_div {
  padding-bottom: 60px;
  background-image: url("./images/wcbg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.workshop_heading {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: var(--text);
  margin-top: 50px;
  text-align: center;
  margin-bottom: 30px;
}
.w_para {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: var(--text);
  text-align: right;
}
.w_para1 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: var(--text);
  text-align: right;
}
.w_para2 {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: var(--text);
  text-align: left;
}
.w_para3 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: var(--text);
  text-align: left;
}
.workshops_para4 {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: var(--text);
  margin-top: 30px;
}
/*-----------------------------------------------------
Hues of the members
-------------------------------------------------------*/
.huemem_div {
  margin-bottom: 90px;
}
.huemem_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  text-align: center;
  margin-top: 90px;
  margin-bottom: 48px;
}
.card-text-huemem {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  text-align: left;
}
.card-text-huemem1 {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  text-align: left;
}
.card-text-huemem2 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  text-align: left;
}
.card-text-huemem3 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  text-align: left;
}
.card_huemem_div {
  background-color: var(--darkblue);
  min-height: 100%;
}
.card-img-huemem {
  width: 362px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  margin-left: 20px !important;
}
/*------------------------------------------
Team Sahaara
--------------------------------------------*/
.sahaara_div {
  background-color: #fff3db;
  height: auto;
}
.sahaara_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  padding-top: 90px;
}
.sahaara_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--text);
  padding-top: 28px;
  margin-bottom: 48px;
}
.card_sahaara_title {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: var(--text);
}
.card_subtitle_sahaara {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--text);
  opacity: 0.85;
}
.card_stext {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--text);
  opacity: 0.8;
}
.sahaara_cards {
  margin-bottom: 130px;
}

.card_sahaara {
  border: 1px solid rgba(59, 59, 83, 0.7);
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  height: 600px;
  width: 100%;
}
/*---------------------------------------------
Start volunteering with us
-----------------------------------------------*/
.volunteer {
  background: #fff3db;
  background-image: url("./images/Volunteeringbg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.volunteer_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  padding-top: 90px;
}
.volunteer_para {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--text);
  padding-top: 28px;
}
.btn-volunteering {
  background: var(--pink);
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin-top: 36px;
  margin-bottom: 90px;
  width: 188px;
  height: 40px;
}
.btn-volunteering:hover {
  background-color: var(--hover-pink);
  color: #ffffff;
}
/*--------------------------------------------------
Paticipants
---------------------------------------------------*/
.participants_div {
  background-color: #fff3db;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 60px;
}
.participants_heading {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  padding-top: 90px;
  margin-bottom: 48px;
}
.participantscard_div {
  background-color: var(--darkblue);
}
.card-text-participants {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  margin-top: 70px;
  margin-bottom: 80px;
}
.card-text-participants1 {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  margin-bottom: 10px;
}
.card-text-participants2 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  margin-bottom: 7px;
}
.card-text-participants3 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 70px;
}
/*---------------------------------------------------
Read HuesLetters
----------------------------------------------------*/
.read_huesletters_div {
  background-color: #fff3db;
}
.read_huesletters_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-top: 90px;
  margin-bottom: 48px;
}
.hl_cards {
  border: 1px solid var(--text);
  box-sizing: border-box;
  border-radius: 4px;
}
.card-title-hl {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: var(--text);
}
.card-text-hl {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: var(--text);
}
.hl_link {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--pink);
  text-decoration: none;
}
.hl_card_div {
  padding-bottom: 60px;
}
.note_editor {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-bottom: 48px;
}
.note_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
  color: var(--text);
  margin-right: 100px;
}
.note_name {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: right;
  color: var(--text);
  margin-right: 100px;
  margin-bottom: 60px;
}
/*-----------------------------------------------------
Footer
-------------------------------------------------------*/
.footer_div {
  background-color: var(--darkblue);
}
.comp_name {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
  color: var(--yellow);
  padding-top: 55px;
  padding-bottom: 12px;
}
.comp_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.quick_links {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  color: #ffffff;
  padding-top: 60px;
}
.footer_links {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  text-decoration: none;
}
footer a:hover {
  color: var(--yellow);
}
.footer_huesletters {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  color: #ffffff;
  padding-top: 60px;
}
.footer_huesletter_p {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.footer_end {
  background: #00162b;
  color: white;
  height: 100px;
  padding-top: 20px;
  opacity: 0.7;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
}
.subs_btn {
  width: 180px;
  height: 40px;
  background: var(--yellow) !important;
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: bolder !important;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.04em;
  color: var(--darkblue) !important;
  text-decoration: none;
  margin-bottom: 18px;
}
.subs_btn:hover {
  background-color: #ceab00 !important;
}
/*----------------------------------------
Contact us pages
-------------------------------------------*/
.contact_uspage_div {
  background-image: url("./images/conbg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.contact_us_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  padding-top: 130px;
}
.contact_us_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: var(--text);
  margin-top: 32px;
}
.contact_us_heading {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: var(--text);
  margin-top: 56px;
}
.contact_us_email {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: var(--text);
  margin-bottom: 36px;
}
.contact_us_follow {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: var(--text);
  margin-top: 40px;
  margin-bottom: 20px;
}
.contact_us_social_media {
  padding-bottom: 90px;
}
.contact_box {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
}
/*=========================
Hues of you
==========================*/
.huesofyou_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 130%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--text);
  margin-left: 100px;
}
.hoy_img {
  width: 355x;
  height: 355px;
}
.huesofyou_title1 {
  display: none;
}
.huesofyou_desc1 {
  display: none;
}
.huesofyou_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: var(--text);
  margin-left: 100px;
  margin-bottom: 100px;
}
.huesofyou_desc2 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: var(--text);
  margin-left: 100px;
}
.hoy_div {
  padding-top: 130px;
  background-image: url("./images/houbg.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.huesofyou_out {
  background-color: #fff3db;
}
.preview_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  padding-top: 123px;
  padding-bottom: 60px;
}
.btn-buy {
  width: 188px;
  height: 40px;
  background: var(--pink);
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
}
.btn-buy:hover {
  background-color: var(--hover-pink);
  color: #ffffff;
}
.buy {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.designer {
  background: var(--darkblue);
  padding-top: 140px;
  padding-bottom: 100px;
}
.des_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ffffff;
  padding-left: 100px;
}
.des_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  text-align: justify;
  padding-top: 40px;
  padding-left: 100px;
}
.des_name {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #ffffff;
  text-align: right;
  padding-top: 23px;
}
.editors_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  padding-top: 90px;
}
.editors_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--text);
  padding-top: 28px;
  padding-bottom: 50px;
}
.editors_card {
  border: 1px solid var(--text);
  box-sizing: border-box;
  border-radius: 4px;
}
.card-title-editors {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: var(--text);
}
.card-text-editors {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--text);
  opacity: 0.85;
}
/*========================
Managers
=========================*/
.manager_div {
  background-color: #fff3db;
}
.manager_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  padding-top: 90px;
  padding-bottom: 60px;
}
.manager_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
  color: var(--text);
  margin-right: 100px;
}
.manager_desc1 {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-align: right;
  color: var(--text);
  margin-right: 100px;
}
.manager_desc2 {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
  color: var(--text);
  margin-left: 100px;
}
.manager_desc4 {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  text-align: right;
  color: var(--text);
}
.manager {
  margin-top: 130px;
  padding-bottom: 90px;
}
/*==============================
journey
=================================*/
.car-journey {
  height: 600px;
  width: 900px !important;
}
/*=================
Authors
====================*/
.author_div {
  background-image: url("./images/authorbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff3db;
  height: auto;
  margin-top: 100px;
}
.author_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  padding-top: 90px;
  padding-bottom: 40px;
}
.card_author_title {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: var(--text);
}
.card_atext {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--text);
  opacity: 0.8;
}
.author_cards {
  margin-bottom: 80px;
}

.card_author {
  border: 1px solid rgba(59, 59, 83, 0.7);
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  height: 550px;
  width: 100%;
}
.authorviewmore {
  display: none;
}
/*====================================
Feed back book
======================================*/
.feedbackbook_div {
  background-image: url("./images/eventsbg2.png");
  padding-bottom: 60px;
}
.feedbackbook_div {
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
}
.feedbackbook_heading {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  padding-top: 90px;
  margin-bottom: 48px;
}
.feedbackbookcard_div {
  background-color: var(--darkblue);
}
.card-text-feedbackbook {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  margin-top: 80px;
  margin-bottom: 50px;
}
.card-text-feedbackbook1 {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
}
.card-text-feedbackbook2 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
}
.card-text-feedbackbook3 {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 100px;
}
.note_div {
  padding-bottom: 30px;
}
/*---------------------------
 Huesradio
 ---------------------------- */
.huesradio_div {
  background-image: url("./images/huesradio_bg.png");
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.huesradio_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 130%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--text);
  margin-left: 100px;
  margin-top: 110px;
}
.huesradio_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 35px;
  margin-left: 100px;
  color: var(--text);
}
.huesradio_img {
  width: 400x;
  height: 400px;
  margin-top: 100px;
}
.huesradio_title1 {
  display: none;
}
.episode_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-top: 90px;
}
.episode_para {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: #000000;
  margin-top: 20px;
}
.btn-subscriberadio {
  width: 188px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.04em;
  color: var(--text);
  margin-left: 100px;
  margin-right: 20px;
}
.btn-resources {
  width: 188px;
  height: 40px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
}
.waves {
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: cover;
}
.btn-resources:hover {
  color: #ffffff;
}
.btn-subscriberadio:hover {
  background-color: whitesmoke;
}
.huesradio_btns {
  margin-bottom: 90px;
  flex-direction: row;
  display: flex;
}
/*=============================
Huesradio bts
===============================*/
.bts_bg {
  background-image: url("./images/teambg.png");
}
.bts_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-top: 90px;
  color: var(--text);
}
.bts_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--text);
  margin-bottom: 60px;
  margin-top: 15px;
}
.hr_title {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: var(--text);
}
.hr_sub {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--text);
  opacity: 0.85;
}
.hr_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--text);
  opacity: 0.8;
}
.card_bts {
  height: 450px;
  margin-bottom: 40px;
  border: 1px black solid;
}
.bts_cont {
  padding-bottom: 60px;
}
.feedback_radio {
  background-image: url("./images/huesradio_bg.png");
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: cover;
}
.btn-radioform {
  width: 167px;
  height: 40px;
  left: 0px;
  top: 0px;
  background: var(--pink);
  border-radius: 4px;
  color: white;
  margin-top: 10px;
}
.btn-radioform:hover {
  background-color: var(--hover-pink);
  color: #ffffff;
}
@media (max-width: 991px) {
  .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .nav-active {
    font-weight: bold;
    border-bottom: 0px solid var(--yellow);
  }
  .silver_award {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 38px;
    text-align: center;
    color: var(--pink);
  }
  .help2 {
    text-align: center;
    margin-bottom: 20px;
    padding-right: 0px;
  }
  .para1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    color: var(--text);
    margin-top: 13px;
    text-align: justify;
    text-justify: inter-word;
    margin-right: 0px;
  }
  .para2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    color: var(--text);
    margin-top: 13px;
    text-align: justify;
    text-justify: inter-word;
    margin-left: 0px;
  }
  .know_more {
    position: static;
    width: 78px;
    height: 19px;
    left: 0px;
    top: 0.5px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: var(--pink);
    flex: none;
    order: 0;
    align-self: center;
    flex-grow: 0;
    text-decoration: none;
  }
  .know_more1 {
    position: static;
    width: 78px;
    height: 19px;
    left: 0px;
    top: 0.5px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: var(--pink);
    flex: none;
    order: 0;
    align-self: center;
    flex-grow: 0;
    text-decoration: none;
    margin-left: 0px;
  }
  .img-left {
    margin-left: 0px;
  }
  .facts_div {
    margin-top: 0px;
  }
  .flex-dir-top {
    flex-direction: column;
  }
  .flex-dir-bottom {
    flex-direction: column-reverse;
  }
  .yourheadings1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: var(--text);
    padding-top: 7px;
  }
  .yourpara {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    color: var(--text);
    margin-top: 13px;
    margin-right: 0px;
    text-align: justify;
    text-justify: inter-word;
  }
  .yourpara1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    color: var(--text);
    margin-top: 13px;
    margin-left: 0px;
    text-align: justify;
    text-justify: inter-word;
  }
  .yourknow_more {
    position: static;
    width: 78px;
    height: 19px;
    left: 0px;
    top: 0.5px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: var(--pink);
    flex: none;
    order: 0;
    align-self: center;
    flex-grow: 0;
    text-decoration: none;
  }
  .yourknow_more1 {
    position: static;
    width: 78px;
    height: 19px;
    left: 0px;
    top: 0.5px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: var(--pink);
    flex: none;
    order: 0;
    align-self: center;
    flex-grow: 0;
    text-decoration: none;
    margin-left: 0px;
  }
  .contactus_heading {
    text-align: center;
  }
  .contactus_desc {
    text-align: center;
  }
  .contactus_email {
    text-align: center;
  }
  .contactus_social_media {
    text-align: center;
  }
  .contactus_div {
    padding-bottom: 90px;
  }
  .carousel {
    background-size: 100vh;
  }
  .prat-img {
    width: 80% !important;
    height: 80% !important;
  }
  .events_heading {
    text-align: center;
  }
  .events_para {
    text-align: center;
  }
  .search_heading {
    text-align: center;
  }
  .card-img-hues {
    width: 400px !important;
    text-align: center !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
  .hues_outer {
    margin-bottom: 50px;
  }
  .carousel_div {
    background-color: #fff3db;
    height: 600px;
    width: 100%;
  }
  .hy_headings {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 38px;
    text-align: center;
    color: var(--text);
  }
  .accordion-toggle {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 19px;
    color: var(--text);
    height: 100px;
  }
  .huesletters_card {
    width: 70%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .huesletters_title_card {
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 65px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #18191f;
  }
  .huesletters_card_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #18191f;
  }
  .okay_img {
    width: 60%;
    height: 80%;
    margin-top: 50px;
  }
  .okay_img_div {
    text-align: center;
  }
  .okay_title {
    text-align: center;
    margin-right: 0px;
  }
  .okay_desc {
    margin-top: 40px;
    text-align: left;
    margin-right: 0px;
  }
  .selfcare_card {
    width: 70%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .selfcare_title_card {
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 65px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #18191f;
  }
  .selfcare_card_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #18191f;
  }
  .card_ssimg {
    height: 50%;
  }
  .neg_img {
    height: 90%;
    width: 90%;
  }
  .neg_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: var(--text);
  }
  .flex-dir-nt {
    flex-direction: column;
  }
  .learn-image {
    padding: 20px;
  }
  .learn_title {
    font-family: Manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 40px;
    font-feature-settings: "liga" off;
    color: var(--text);
  }
  .learn_subtitle {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: var(--text);
  }
  .breakingstig_card {
    width: 70%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .breakingstig_title_card {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 65px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 20px;
  }
  .breakingstig_card_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: var(--text);
    text-align: justify;
  }
  .accordion-toggle-adhd {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 19px;
    color: var(--text);
  }
  .repo_img {
    width: 100%;
    height: 100%;
  }
  .repo_names {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    color: #ffffff;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .repo_desc {
    font-family: Nunito;
    font-style: normal;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
    text-align: left;
    opacity: 0.9;
    margin-top: 15px;
  }
  .card-link-hotmrepo {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    text-decoration: none;
  }
  .btn-hotmrepo {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.04em;
    color: var(--pink);
    margin-bottom: 50px;
    margin-top: 30px;
    text-align: center;
  }
  .abt_heading {
    margin-top: 200px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 47px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-left: 120px;
    margin-bottom: 60%;
  }
  .abt_para1 {
    display: none;
  }
  .abt_para2 {
    display: contents;
    position: relative;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: var(--text);
    text-align: center;
  }
  .card-img-huemem {
    width: 250px !important;
    height: 276px !important;
    text-align: center !important;
    margin-top: 30px;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
  }
  .card_sahaara_title {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: var(--text);
  }
  .card_subtitle_sahaara {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var(--text);
    opacity: 0.85;
  }
  .card_stext {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--text);
    opacity: 0.8;
  }
  .flex-dir-contact {
    flex-direction: column-reverse;
  }
  .card_huemem_div {
    background-color: var(--darkblue);
    height: 100%;
  }
  .events_buttons {
    color: black;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.04em;
  }
  .sahaaraviewmore {
    display: none;
  }
  .huesofyou_title {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-size: 54px;
    line-height: 130%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
    margin-left: 0px;
    text-align: center;
    display: none;
  }
  .huesofyou_title1 {
    display: block;
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 54px;
    line-height: 130%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
    margin-left: 0px;
    text-align: center;
  }
  .huesofyou_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 38px;
    color: var(--text);
    margin-left: 0px;
    margin-bottom: 0px;
    text-align: center;
    margin-bottom: 60px;
  }
  .huesofyou_desc1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 38px;
    color: var(--text);
    margin-left: 0px;
    margin-bottom: 0px;
    text-align: center;
    display: block;
  }
  .huesofyou_desc2 {
    display: none;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 38px;
    color: var(--text);
    margin-left: 0px;
    margin-bottom: 0px;
    text-align: center;
  }
  .hoy_div {
    padding-top: 150px;
  }
  .huesofyou_out {
    background-color: #fff3db;
  }
  .preview_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    padding-top: 123px;
    padding-bottom: 60px;
  }
  .hoy_img {
    width: 400px;
    height: 400px;
  }
  .designer {
    padding-top: 80px;
    padding-bottom: 50px;
  }
  .des_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #ffffff;
    padding-left: 0px;
    text-align: center;
    padding-top: 50px;
  }
  .des_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
    text-align: justify;
    padding-top: 40px;
    padding-left: 0px;
  }
  .des_name {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 38px;
    color: #ffffff;
    text-align: right;
    padding-top: 23px;
  }
  .car-journey {
    height: auto;
  }
  .bm_row {
    padding-bottom: 0px;
  }
  .card_atext {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--text);
    opacity: 0.8;
  }
  .manager_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: justify;
    color: var(--text);
    margin-right: 0px;
    margin-top: 30px;
  }
  .manager_desc1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    text-align: right;
    color: var(--text);
    margin-right: 0px;
  }
  .manager_desc2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: justify;
    color: var(--text);
    margin-left: 0px;
    margin-top: 30px;
  }
  .manager_desc4 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    text-align: right;
    color: var(--text);
  }
  .manager {
    margin-top: 50px;
    padding-bottom: 50px;
  }
  .note_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: justify;
    color: var(--text);
    margin-right: 0px;
    margin-top: 50px;
  }
  .note_name {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: right;
    color: var(--text);
    margin-right: 0px;
    margin-bottom: 60px;
  }
  .note_div {
    padding-bottom: 0px;
  }

  .huesradio_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 35px;
    margin-left: 0px;
    color: var(--text);
    text-align: justify;
  }
  .huesradio_img {
    width: 355x;
    height: 355px;
    margin-top: 20px;
  }
  .huesradio_title1 {
    display: block;
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 130%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
    margin-left: 0px;
    margin-top: 110px;
  }
  .huesradio_title {
    display: none;
  }
  .award_title1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    line-height: 130%;
    margin-left: 0px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fff3db;
    text-align: center;
    margin-top: 60px;
  }
  .award_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin-left: 0px;
    color: #ffffff;
    text-align: justify;
  }
  .award_img {
    width: 450px;
    height: 528px;
  }
  .award_title1 {
    display: block;
  }
  .award_title {
    display: none;
  }
  .huesradio_btns {
    text-align: center;
    align-content: center;
    justify-content: center;
  }
  .btn-subscriberadio {
    margin-left: 0px;
  }
  .reco_text {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
  }
  .logodw {
    width: 50% !important;
    height: 80% !important;
  }
}

@media (max-width: 768px) {
  .silver_award {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    text-align: center;
    color: var(--pink);
  }
  .landbg {
    background-image: url("./images/landbgsmall.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .helpyourselfhead_div {
    background-image: url("./images/hysmall.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .facts_div {
    position: relative;
    margin-top: 0px;
  }
  .flex-dir {
    flex-direction: column;
  }
  .yourbg {
    background-image: url("./images/yourhelpsmall.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .helpbg {
    background-image: url("./images/helpbgsmall.png");
    background-repeat: repeat-y;
  }
  .collabus_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .footer_style {
    text-align: center !important;
  }
  .card-img-popular {
    width: 300px !important;
    height: 250px;
    border-radius: 4px;
    margin-top: 15px;
    text-align: center !important;
  }
  .card-title {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: var(--text);
  }
  .card-subtitle {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: var(--text);
    opacity: 0.85;
  }
  .card-text {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: var(--text);
  }
  .card-img-hues {
    width: 350px !important;
    height: 385px !important;
    text-align: center !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
  .hues_outer {
    margin-bottom: 40px;
  }
  .helpyourself_img {
    width: 160px;
    height: 181px;
  }
  .accordion-toggle {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    color: var(--text);
    height: 100px;
  }
  .answer_faq {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: #000000;
  }
  .huesletters_card {
    width: 70%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .huesletters_title_card {
    font-family: Rubik;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .huesletters_card_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: var(--text);
  }
  .btn-read_huesletters {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #ffffff;
    background: var(--pink);
    border-radius: 4px;
  }
  .btn-subscribe_huesletters {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.04em;
    color: var(--pink);
    text-decoration: none;
    vertical-align: middle;
    border: 1px solid var(--pink);
    box-sizing: border-box;
    margin-left: 12px;
  }
  .nt {
    text-align: center;
    background-image: url("./images/nt.png");
  }
  .flex-dir-nt {
    flex-direction: column;
  }
  .happy_title {
    padding-top: 70px;
  }
  .happy_desc {
    padding-bottom: 70px;
  }
  .okay_title {
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
  }
  .okay_desc {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: #ffffff;
  }
  .selfcare_title_card {
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #18191f;
    padding-top: 18px;
  }
  .bm {
    text-align: center;
  }
  .bm_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #18191f;
    margin-top: 30px;
  }
  .flex-dir-bm {
    flex-direction: column;
  }
  .selfcare_div {
    text-align: center;
  }
  .card_ssimg {
    height: 50%;
  }
  .safespace_para {
    font-size: 18px;
  }
  .selfcareinfo_card_title {
    text-align: left;
  }
  .selfcareinfo_card_desc {
    text-align: left;
  }
  .breathinginfo_desc {
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    color: rgba(59, 59, 83, 0.8);
    vertical-align: middle;
  }
  .breathinginfo_desc1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    color: white;
    vertical-align: middle;
  }
  .breathing_info_p {
    font-size: 18px;
    line-height: 160%;
  }
  .somatic_title1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: var(--text);
    opacity: 0.9;
    margin-top: 40px;
    margin-left: 30px;
    margin-bottom: 20px;
    text-align: left;
  }
  .somatic_desc1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: var(--text);
    opacity: 0.8;
    margin-left: 30px;
    text-align: left;
  }
  .somatic_title2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: white;
    opacity: 0.9;
    margin-top: 40px;
    margin-left: 30px;
    margin-bottom: 20px;
    text-align: left;
  }
  .somatic_desc2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: white;
    opacity: 0.8;
    margin-left: 30px;
    text-align: left;
  }
  .flex-dir-learn1 {
    flex-direction: column;
  }
  .flex-dir-learn2 {
    flex-direction: column-reverse;
  }
  .learn_title {
    font-family: Manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 40px;
    font-feature-settings: "liga" off;
    color: var(--text);
  }
  .learn_subtitle {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: var(--text);
  }
  .breakingstig_card {
    width: 80%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .breakingstig_title_card {
    font-weight: 800;
    font-size: 38px;
    line-height: 65px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 20px;
  }
  .breakingstig_card_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: var(--text);
    text-align: center;
  }
  .accordion-toggle-adhd {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    color: var(--text);
    height: 90px;
  }
  .repo_img {
    width: 400px;
    height: 400px;
    margin-top: 10px;
  }
  .repo_names {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 38px;
    color: #ffffff;
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
  }
  .repo_desc {
    font-family: Nunito;
    font-style: normal;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    text-align: left;
    opacity: 0.9;
    margin-top: 15px;
    margin-left: 30px;
  }
  .card-link-hotmrepo {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    text-decoration: none;
    margin-left: 30px;
  }
  .abt_heading {
    margin-top: 200px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 47px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-left: 80px;
    margin-bottom: 50%;
  }
  .abt_para1 {
    display: none;
  }
  .abt_para2 {
    position: relative;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: var(--text);
    text-align: center;
  }
  .workshop_heading {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-bottom: 40px;
    margin-top: 40px;
  }
  .w_para {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: var(--text);
  }
  .w_para1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: var(--text);
  }
  .w_para2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: var(--text);
  }
  .w_para3 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: var(--text);
  }
  .card-img-huemem {
    width: 150px;
    height: 165px;
    text-align: center !important;
    margin-top: 20px;
  }
  .card-text-huemem {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
  }
  .card-text-huemem1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
  }
  .card-text-huemem2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
  }
  .card-text-huemem3 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
  }
  .card_sahaara_title {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: var(--text);
  }
  .card_subtitle_sahaara {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var(--text);
    opacity: 0.85;
  }
  .card_stext {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--text);
    opacity: 0.8;
  }
  .events_buttons {
    color: black;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.04em;
  }
  .car_hide {
    display: none;
  }
  .sahaaraviewmore {
    display: block;
    text-align: center;
    padding-bottom: 60px;
    padding-top: 24px;
  }
  .sahaaralinkmore {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.04em;
    color: var(--pink);
    text-decoration: none;
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
  }
  button:active {
    outline: none;
    border: none;
  }

  button:focus {
    outline: 0;
  }
  button,
  button:hover,
  button:focus,
  button:active {
    text-decoration: none;
    color: var(--pink);
  }
  .contactus_div {
    padding-bottom: 90px;
    background-image: url("./images/contactsmall.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .contact_uspage_div {
    background-image: url("./images/consm.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .eventslist_div {
    background-image: url("./images/eventssmall.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .faqs_div {
    background-color: #fff3db;
    background-image: url("./images/faqsmall.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .selfcareinfo_div {
    background-image: url("./images/selfcaresmall.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .learn-div {
    background-color: #fff3db;
    background-image: url("./images/learnsmall.png");
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .lgbtq_div {
    background-color: #fff3db;
    background-image: url("./images/lgbtsmall.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .anxiety_div {
    background-image: url("./images/anxietysmall.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .ocd_div {
    background-image: url("./images/ocdsmall.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .huesofyou_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 44px;
    line-height: 130%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
    margin-left: 0px;
    text-align: center;
  }
  .huesofyou_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    color: var(--text);
    margin-left: 0px;
    margin-bottom: 60px;
    text-align: center;
  }
  .huesofyou_desc1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    color: var(--text);
    margin-left: 0px;
    text-align: center;
  }
  .huesofyou_desc2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    color: var(--text);
    margin-left: 0px;
    margin-bottom: 60px;
    text-align: center;
  }
  .preview_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    padding-top: 123px;
    padding-bottom: 60px;
  }

  .des_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 37px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #ffffff;
    padding-left: 0px;
    text-align: center;
    padding-top: 50px;
  }
  .des_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    text-align: justify;
    padding-top: 40px;
    padding-left: 0px;
  }
  .des_name {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #ffffff;
    text-align: right;
    padding-top: 23px;
  }
  .dayoung {
    width: 250px;
    height: 270px;
  }
  .authorviewmore {
    display: block;
  }
  .author_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    padding-top: 90px;
    padding-bottom: 40px;
  }
  .card_author_title {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 38px;
    text-align: center;
    color: var(--text);
  }
  .card_atext {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--text);
    opacity: 0.8;
  }
  .author_cards {
    margin-bottom: 80px;
  }
  .card_author {
    border: 1px solid rgba(59, 59, 83, 0.7);
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    height: auto;
    width: 100%;
  }
  .authorlinkmore {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.04em;
    color: var(--pink);
    text-decoration: none;
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .authorviewmore {
    text-align: center;
  }
  .author_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .author_div {
    background-image: url("./images/helpbgsmall.png");
    background-repeat: repeat-y;
  }
  .huesradio_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin-left: 0px;
    color: var(--text);
    text-align: justify;
  }
  .huesradio_img {
    width: 355x;
    height: 355px;
  }
  .huesradio_title1 {
    display: block;
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 100%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
    margin-left: 0px;
    margin-top: 110px;
  }
  .huesradio_title {
    display: none;
  }

  .episode_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 90px;
  }
  .episode_para {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
  }
  .bts_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding-top: 90px;
    color: var(--text);
  }
  .bts_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--text);
    margin-bottom: 60px;
    margin-top: 15px;
  }
  .hr_title {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: var(--text);
  }
  .hr_sub {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--text);
    opacity: 0.85;
  }
  .hr_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--text);
    opacity: 0.8;
  }
  .card_bts {
    height: 500px;
    margin-bottom: 40px;
    border: 1px black solid;
  }
  .reco_text {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
  }
  a .reco_links {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #ffffff!important;
  }
  .reco_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff3db;
    margin-bottom: 35px;
  }
  .logodw {
    width: 50% !important;
    height: 80% !important;
  }
  .dw_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 60px;
    margin-bottom: 40px;
  }
}
@media (max-width: 600px) {
  .silver_award {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: var(--pink);
  }
  .flex-direction {
    flex-direction: column;
  }
  .heading_facts {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding-top: 60px;
  }
  .sub_heading_facts {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    opacity: 0.9;
  }
  .heading3 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
  }
  .p1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
  }
  .facts_div {
    margin-top: 0px;
  }
  .facts_source {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    margin-right: 50px;
    margin-bottom: 60px;
  }
  .services_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 40px;
    margin-bottom: 0;
  }
  .help1 {
    width: 225px;
    height: 323px;
  }
  .headings1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: var(--text);
  }
  .para {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: var(--text);
  }
  .help2 {
    padding-right: 0px;
  }
  .heading_impact {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #ffffff;
    padding-top: 60px;
  }
  .sub_heading_impact {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    opacity: 0.9;
  }
  .impact_div {
    position: relative;
    background-color: var(--darkblue);
    height: auto;
    width: 100%;
    left: 0px;
    border-radius: 0px;
    text-align: center;
    padding-bottom: 60px;
  }
  .impact_headings {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
  }
  .impact_subheadings {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
  }
  .heading_collab {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    padding-top: 60px;
  }

  .yourhelp_title {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .yourhelp1 {
    width: 250px;
    height: 250px;
  }
  .yourheadings1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: var(--text);
  }
  .yourhelp2 {
    width: 250px;
    height: 250px;
    margin-bottom: 30px;
  }
  .donate_title {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: var(--text);
  }
  .done_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
  }
  .btn-donate {
    border: 1px solid var(--pink);
    box-sizing: border-box;
    border-radius: 4px;
    height: 38px;
    width: 150px;
    border-radius: 4px;
  }
  .hues_div {
    background-color: var(--darkblue);
    width: 100%;
    padding-bottom: 60px;
  }
  .hues_title {
    padding-top: 60px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 47px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 48px;
  }
  .collabus_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    padding-top: 60px;
  }
  .collabus_para {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: var(--text);
  }
  .btn-collaborate {
    height: 38px;
    width: 150px;
    border-radius: 4px;
    margin-bottom: 60px;
  }
  .contactus_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-bottom: 40px;
    margin-top: 60px;
  }
  .contactus_heading {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
    text-align: center;
  }
  .contactus_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--text);
  }
  .contactus_email {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: var(--text);
    text-align: center;
  }
  .contactus_follow {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    color: var(--text);
    text-align: center;
  }
  .contactus_social_media {
    text-align: center;
    padding-bottom: 15px;
  }
  .contactus_div {
    padding-bottom: 60px;
  }
  .events_heading {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .events_para {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: var(--text);
  }
  .search_heading {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: var(--text);
  }
  .popularevents_heading {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .popular_events_desc {
    display: none;
  }
  .card-title {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: var(--text);
  }
  .card-subtitle {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: var(--text);
    opacity: 0.85;
  }
  .card-text {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: var(--text);
  }
  .btn-eventslist {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.04em;
    color: var(--pink);
  }
  .card-title-events {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: var(--text);
  }
  .card-subtitle-events {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: var(--text);
    opacity: 0.85;
  }
  .card-text-events {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: var(--text);
  }
  .helpyourself_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .list_link {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.04em;
    text-decoration-line: underline;
    color: var(--pink);
    padding-bottom: 50px;
  }
  .hy_headings {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--darkblue);
  }
  .accordion-toggle {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--text);
    height: 90px;
  }
  .acc_items {
    margin-bottom: 24px;
  }
  .answer_faq {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: var(--text);
  }
  .faqs_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .huesletters_card {
    width: 80%;
  }
  .huesletters_title_card {
    font-family: Rubik;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .huesletters_card_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: var(--text);
  }
  .negative_title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 60px;
    margin-bottom: 30px;
  }
  .btn-nt-getstarted {
    width: 150px;
    height: 38px;
    border: 1px solid var(--pink);
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.04em;
    color: var(--pink);
    text-decoration: none;
    margin-top: 20px;
  }
  .nt {
    text-align: center;
  }
  .nt_desc {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #18191f;
  }
  .happy_title {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    color: #18191f;
  }
  .happy_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #18191f;
  }
  .okay_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
  }
  .okay_desc {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: #ffffff;
  }
  .bm_title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .btn-bm-getstarted {
    width: 150px;
    height: 38px;
  }
  .bm {
    text-align: center;
  }
  .bm_desc {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #18191f;
    margin-top: 30px;
  }
  .selfcare_title_card {
    font-family: Rubik;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .safespace_subtitle {
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: var(--text);
  }
  .card_sstitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: var(--text);
  }
  .card_sssubtitle {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--text);
  }
  .card_ssimg {
    height: 50%;
  }
  .sharestory_title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .sharestory_para {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: var(--text);
  }
  .btn-share {
    width: 150px;
    height: 38px;
    background-color: var(--pink);
    border-radius: 4px;
    color: white;
    margin-bottom: 90px;
    margin-top: 24px;
    font-family: Nunito;
    font-style: bold;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.04em;
  }
  .selfcareinfo_title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    padding-top: 140px;
  }
  .selfcareinfo_card_title {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    color: var(--text);
    margin-left: 15px;
    text-align: left;
    margin-top: 40px;
  }
  .selfcareinfo_card_desc {
    font-size: 16px;
    line-height: 160%;
    color: var(--text);
    margin-top: 12px;
    text-align: left;
    margin-left: 15px;
    margin-right: 15px;
    padding-bottom: 40px;
  }
  .breathinginfo_desc {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: rgba(59, 59, 83, 0.8);
    vertical-align: middle;
  }
  .breathinginfo_desc1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: white;
    vertical-align: middle;
  }
  .breathinginfo_title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .breathinginfo_desc {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: rgba(59, 59, 83, 0.8);
    vertical-align: middle;
  }
  .breathinginfo_desc1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: white;
    vertical-align: middle;
  }
  .breathing_info_p {
    font-size: 16px;
    line-height: 160%;
  }
  .somatic_title1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
    color: var(--text);
    opacity: 0.9;
    margin-top: 40px;
    margin-left: 0px;
    margin-bottom: 20px;
    text-align: left;
  }
  .somatic_desc1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
    color: var(--text);
    opacity: 0.8;
    margin-left: 0px;
    text-align: left;
  }
  .somatic_title2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
    color: white;
    opacity: 0.9;
    margin-top: 40px;
    margin-left: 0px;
    margin-bottom: 20px;
    text-align: left;
  }
  .somatic_desc2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
    color: white;
    opacity: 0.8;
    margin-left: 0px;
    text-align: left;
  }
  .neg_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: var(--text);
  }
  .neg_stop {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .neg_para {
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: #000000;
  }
  .learn_title {
    font-family: Manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 40px;
    font-feature-settings: "liga" off;
    color: var(--text);
  }
  .learn_subtitle {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: var(--text);
  }
  .breakingstig_card {
    padding-left: 10px;
    padding-right: 10px;
  }
  .breakingstig_title_card {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .breakingstig_card_desc {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: #18191f;
  }
  .btn-breakingstig {
    width: 90%;
    height: 38px;
    background-color: var(--pink);
    border-radius: 4px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #ffffff;
    margin-bottom: 30px;
  }
  .lgbtq_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
  }
  .lgbtq_para {
    font-family: Nunito;
    font-size: 16px;
    line-height: 160%;
    color: var(--text);
    text-align: left;
    margin-top: 40px;
    margin-bottom: 90px;
  }
  .anxiety_title {
    font-weight: 900;
    font-size: 28px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
  }
  .anxiety_para {
    font-size: 14px;
    line-height: 160%;
    color: var(--text);
  }
  .anxiety_heading {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: var(--text);
    margin-top: 40px;
  }
  .anxiety_link {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: var(--pink);
    text-decoration: none;
  }
  .al_div {
    margin-bottom: 60px;
  }
  .breaking_stigma_heading {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .breaking_stigma_sub {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: var(--text);
  }
  .breaking_stigma_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: var(--text);
  }
  .ocd_heading {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 130px;
    margin-bottom: 40px;
  }
  .ocd_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: var(--text);
  }
  .ocd_disclaimer {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: var(--text);
    margin-bottom: 90px;
  }
  .adhd_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
  }
  .accordion-toggle-adhd {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: var(--text);
    height: 80px;
  }
  .acc_items_adhd {
    margin-bottom: 24px;
  }
  .answer_adhd {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: #000000;
    text-align: left;
  }
  .repo_img {
    width: 300px;
    height: 300px;
    margin-top: 10px;
  }
  .repo_names {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 38px;
    color: #ffffff;
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
  }
  .repo_desc {
    font-family: Nunito;
    font-style: normal;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    text-align: left;
    opacity: 0.9;
    margin-top: 15px;
    margin-left: 0px;
  }
  .card-link-hotmrepo {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    color: #ffffff;
    text-decoration: none;
    margin-left: 0px;
  }
  .repo_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .repo_txt {
    font-family: Nunito;
    font-style: normal;
    font-size: 15px;
    line-height: 25px;
    color: var(--text);
    text-align: left;
    margin-top: 30px;
    text-align: center;
    margin-bottom: 0px;
  }
  .repo_txt1 {
    font-family: Nunito;
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: var(--text);
    text-align: left;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 0px;
  }
  .drop_mail {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: var(--pink);
    border: none;
    border: none;
    background-color: white;
    text-decoration: underline;
  }
  .abt_heading {
    margin-top: 150px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-left: 40px;
    margin-bottom: 50%;
  }
  .abt_para1 {
    display: none;
  }
  .abt_para2 {
    position: relative;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    color: var(--text);
    text-align: center;
  }
  .huemem_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .volunteer_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    padding-top: 60px;
  }
  .volunteer_para {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: var(--text);
    padding-top: 28px;
  }
  .btn-volunteer {
    background: var(--pink);
    border-radius: 4px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #ffffff;
    margin-top: 28px;
    margin-bottom: 60px;
  }
  .card_sahaara {
    height: 560px;
    width: 100%;
  }
  .sahaara_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .sahhara_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--text);
  }
  .participants_heading {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .card-text-participants {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    margin-top: 30px;
  }
  .card-text-participants1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    color: #ffffff;
    margin-bottom: 7px;
  }
  .card-text-participants2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
  }
  .card-text-participants3 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    margin-bottom: 30px;
  }
  .people_heading {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .card-text-people {
    font-family: Nunito;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #ffffff;
    margin-top: 30px;
  }
  .card-text-people1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .card-text-people2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    margin-bottom: 30px;
  }
  .read_huesletters_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 47px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 90px;
  }
  .btn-hotmrepo {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.04em;
    color: var(--pink);
    margin-bottom: 50px;
    margin-top: 0px;
    text-align: center;
  }
  .eventslist_heading {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 47px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 60px;
  }
  .hotmeve {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.04em;
    color: var(--pink);
    text-decoration: none;
  }
  .eventslist_subheading {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 48px;
  }
  .events_buttons {
    color: black;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.04em;
  }
  .contact_us_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    text-align: center;
  }
  .contact_us_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: var(--text);
    text-align: center;
  }
  .contact_us_heading {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: var(--text);
    text-align: center;
  }
  .contact_us_email {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: var(--text);
    text-align: center;
  }
  .contact_us_follow {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: var(--text);
    text-align: center;
  }
  .contact_us_social_media {
    text-align: center;
  }
  .social_media_con {
    width: 30px !important;
    height: 30px !important;
    padding-right: 10px !important;
  }
  .selftalk_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    padding-top: 120px;
    padding-bottom: 24px;
  }
  .selftalk_img {
    width: 70%;
    height: 90%;
  }
  .selftalk_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: #18191f;
    text-align: justify;
  }
  .selftalk_div {
    background-color: #fff3db;
    padding-bottom: 20px;
  }
  .journey_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .workshops_para4 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: var(--text);
  }
  .huesofyou_title1 {
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 130%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
    margin-left: 0px;
    text-align: center;
  }
  .huesofyou_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: var(--text);
    margin-left: 0px;
    margin-bottom: 60px;
    text-align: center;
  }
  .huesofyou_desc1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: var(--text);
    margin-left: 0px;
    margin-bottom: 0px;
    text-align: center;
  }
  .huesofyou_desc2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: var(--text);
    margin-left: 0px;
    margin-bottom: 60px;
    text-align: center;
  }
  .preview_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    padding-top: 60px;
    padding-bottom: 24px;
  }
  .hoy_img {
    width: 300px;
    height: 300px;
  }
  .editors_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 47px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    padding-top: 90px;
  }
  .editors_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: var(--text);
    padding-top: 28px;
    padding-bottom: 50px;
  }
  .editors_card {
    border: 1px solid var(--text);
    box-sizing: border-box;
    border-radius: 4px;
  }
  .card-title-editors {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    color: var(--text);
  }
  .card-text-editors {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--text);
    opacity: 0.85;
  }
  .des_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #ffffff;
    padding-left: 0px;
  }
  .des_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
    text-align: justify;
    padding-top: 40px;
    padding-left: 0px;
  }
  .des_name {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
    text-align: right;
    padding-top: 23px;
  }
  .safespace_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    padding-top: 130px;
  }
  .read_huesletters_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 60px;
    margin-bottom: 48px;
  }
  .btn-read_huesletters {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #ffffff;
    background: var(--pink);
    border-radius: 4px;
    width: 160px;
    height: 35px;
  }
  .btn-subscribe_huesletters {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.04em;
    color: var(--pink);
    text-decoration: none;
    vertical-align: middle;
    border: 1px solid var(--pink);
    box-sizing: border-box;
    margin-left: 0px;
    width: 160px;
    height: 35px;
    margin-top: 10px;
  }
  .hl_buttons {
    display: flex;
    flex-direction: column;
  }
  .hoy_div {
    background-image: url("./images/houbg.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .repo_div {
    background-image: url("./images/reposmall.png");
    background-size: 100% auto;
    background-repeat: repeat;
  }
  .collab_div {
    padding-bottom: 60px;
  }
  .volunteer {
    background: #fff3db;
    background-image: url("./images/volsmall.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .workshops_div {
    padding-bottom: 30px;
    background-image: url("./images/wcsmall.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .card-text-feedbackbook1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
  }
  .card-text-feedbackbook2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
  }
  .card-text-feedbackbook3 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
  }
  .feedbackbook_heading {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .search_therapist {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: var(--text);
    margin-bottom: 20px;
  }
  .buy_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--text);
  }
  .manager_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    padding-top: 60px;
    padding-bottom: 24px;
  }
  .sos {
    width: 45px;
    height: 45px;
  }
  .note_editor {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .note_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: justify;
    color: var(--text);
    margin-right: 0px;
    margin-top: 30px;
  }
  .note_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: justify;
    color: var(--text);
    margin-right: 0px;
    margin-top: 50px;
  }
  .note_name {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: var(--text);
    margin-bottom: 60px;
  }
  .safespace_div {
    background-image: url("./images/safespbg.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .huesradio_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin-left: 0px;
    color: var(--text);
    text-align: justify;
  }
  .huesradio_img {
    width: 300px;
    height: 300px;
  }
  .huesradio_title1 {
    display: block;
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 100%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
    margin-left: 0px;
    margin-top: 110px;
  }
  .huesradio_title {
    display: none;
  }

  .huesradio_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin-left: 0px;
    color: var(--text);
    text-align: justify;
  }

  .award_title1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 100%;
    margin-left: 0px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fff3db;
    text-align: center;
    margin-top: 60px;
  }
  .award_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin-left: 0px;
    color: #ffffff;
    text-align: justify;
  }
  .award_img {
    width: 400px;
    height: 470px;
  }
  .award_title1 {
    display: block;
  }
  .award_title {
    display: none;
  }
  .episode_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 40px;
  }
  .episode_para {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
  }
  .bts_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding-top: 60px;
    color: var(--text);
  }
  .bts_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: var(--text);
    margin-bottom: 30px;
    margin-top: 15px;
  }
  .hr_title {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: var(--text);
  }
  .hr_sub {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--text);
    opacity: 0.85;
  }
  .hr_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--text);
    opacity: 0.8;
  }
  .card_bts {
    height: 450px;
    margin-bottom: 40px;
    border: 1px black solid;
  }
  .btn-radioform {
    width: 140px;
    height: 30px;
    left: 0px;
    top: 0px;
    line-height: 10px;
    background: var(--pink);
    border-radius: 4px;
    color: white;
    margin-top: 10px;
  }
  .btn-radioform:hover {
    background-color: var(--hover-pink);
    color: #ffffff;
  }
  .reco_text {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
  }
  .dw_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 60px;
    margin-bottom: 40px;
  }
}
@media (max-width: 500px) {
  .award_img {
    width: 350px;
    height: 410px;
  }
  .huesradio_btns {
    flex-direction: column;
    text-align: center;
  }
  .btn-subscriberadio {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .reco_text {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
  }
}

@media (max-width: 382px) {
  .silver_award {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    color: var(--pink);
  }
  .card-img-huemem {
    width: 200px !important;
    height: 220px !important;
    text-align: center !important;
    margin-top: 10px;
  }
  .contactus_email {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: var(--text);
    text-align: center;
  }
  .social_media {
    width: 35px !important;
    height: 35px !important;
    padding-right: 15px !important;
  }
  .social_media:hover {
    background-color: #020c14 !important;
  }
  .btn-eventslist {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--pink);
  }
  .acc_items {
    margin-bottom: 24px;
  }
  .huesletters_card {
    width: 90%;
  }
  .huesletters_title_card {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .huesletters_card_desc {
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: var(--text);
  }
  .btn-read_huesletters {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #ffffff;
    background: var(--pink);
    border-radius: 4px;
    width: 160px;
    height: 35px;
  }
  .btn-subscribe_huesletters {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.04em;
    color: var(--pink);
    text-decoration: none;
    vertical-align: middle;
    border: 1px solid var(--pink);
    box-sizing: border-box;
    margin-left: 0px;
    width: 160px;
    height: 35px;
    margin-top: 10px;
  }
  .hl_buttons {
    flex-direction: column;
  }
  .selfcare_card {
    width: 90%;
  }
  .selfcare_title_card {
    font-family: Rubik;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
  }
  .selfcare_card_desc {
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: var(--text);
  }
  .read_selfcare {
    font-size: 12px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #ffffff;
    background: var(--pink);
    border-radius: 4px;
    width: 130px;
    height: 35px;
  }
  .card_sstitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--text);
  }
  .card_sssubtitle {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: var(--text);
  }
  .card_ssimg {
    height: 45%;
  }
  .breathinginfo_desc {
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    color: rgba(59, 59, 83, 0.8);
    vertical-align: middle;
  }
  .breathinginfo_desc1 {
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    color: white;
    vertical-align: middle;
  }
  .learn_img {
    padding-bottom: 20px;
  }
  .abt_heading {
    margin-top: 120px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-left: 20px;
    margin-bottom: 50%;
  }
  .abt_para1 {
    display: none;
  }
  .hands_img {
    width: 80%;
    height: 80%;
  }
  .workshop_heading {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--text);
    margin-top: 90px;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 40px;
  }
  .btn-hotmrepo {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.04em;
    color: var(--pink);
    margin-bottom: 50px;
    margin-top: 0px;
    text-align: center;
  }

  .card-img-popular {
    width: 220px !important;
    height: 180px;
    border-radius: 4px;
    text-align: center !important;
  }
  .card-img-hues {
    width: 200px !important;
    height: 220px !important;
    text-align: center !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
  .card-text-feedbackbook {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .card-text-feedbackbook1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
  }
  .card-text-feedbackbook2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;
  }
  .card-text-feedbackbook3 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    margin-bottom: 50px;
  }
  .social_media_h {
    width: 25px !important;
    height: 25px !important;
    margin-right: 8px;
  }
  .social_media_con {
    width: 25px !important;
    height: 25px !important;
    margin-right: 8px;
  }
  .huesradio_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-left: 0px;
    color: var(--text);
    text-align: justify;
  }
  .huesradio_img {
    width: 250px;
    height: 250px;
  }
  .huesradio_title1 {
    display: block;
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 90%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
    margin-left: 0px;
    margin-top: 110px;
  }
  .huesradio_title {
    display: none;
  }

  .award_img {
    width: 270px;
    height: 315px;
  }
}
@media (max-width: 462px) {
  .card-img-huemem {
    width: 200px !important;
    height: 220px !important;
    text-align: center !important;
    margin-top: 10px;
  }
  .card-img-hues {
    width: 250px !important;
    height: 275px !important;
    text-align: center !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
  .hoy_img {
    width: 250px;
    height: 250px;
  }
  .manager-img1 {
    width: 80%;
    height: 100%;
  }
  .manager-img2 {
    width: 80%;
    height: 100%;
  }
  .note_img {
    width: 80%;
    height: 100%;
  }
  .award_img {
    width: 300px;
    height: 350px;
  }
  .reco_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff3db;
    margin-bottom: 35px;
  }
  .logodw {
    width: 80% !important;
    height: 80% !important;
  }
}
@media (max-width: 400px) {
  .repo_img {
    width: 230px;
    height: 230px;
    margin-top: 10px;
  }
}
@media (min-width: 768px) and (max-width: 769px) {
  .repo_img {
    width: 300px;
    height: 250px;
  }
}
@media (max-width: 1200px) {
  .abouthead_div {
    width: 100%;
    height: 100%;
    background-image: url("./images/about1.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
/* ===========================================
Huesmerch
============================================== */
@media (max-width: 991px) {
  .huesmerch_div {
    background-image: url("./images/merchbg.png");
    background-color: #fff3db;
    background-repeat: no-repeat;
    width: 100%;
    height: 100% !important;
    background-size: cover;
    margin: 0;
    padding: 0;
  }
}
.huesmerch_div {
  background-image: url("./images/merchbg.png");
  background-color: #fff3db;
  background-repeat: no-repeat;
  width: 100%;
  height: 866px;
  background-size: cover;
  margin: 0;
  padding: 0;
}
.card_merch {
  border: 1px solid black;
}
.huesmerch_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 130%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--text);
  margin-left: 100px;
  margin-top: 110px;
}
.huesmerch_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  margin-left: 100px;
  color: var(--text);
}
.huesmerch_sub {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-left: 100px;
  color: var(--text);
}
.huesmerch_img {
  width: 400x;
  height: 400px;
  margin-top: 70px;
  box-shadow: 8px 8px 5px #5e5a5a;
}
.huesmerch_title1 {
  display: none;
}
.products_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-top: 90px;
  margin-bottom: 50px;
}

.btn-buymerch {
  width: 188px;
  height: 40px;
  background: var(--pink);
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin-left: 100px;
  margin-top: 30px;
  margin-bottom: 50px;
}
.btn-buymerch:hover {
  background-color: var(--hover-pink);
  color: #ffffff;
}
.prod_div {
  margin-top: 30px;
}
.prod_headings {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
}
.prod_desc {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  color: var(--text);
  text-align: justify;
}

@media (max-width: 991px) {
  .huesmerch_title1 {
    display: block;
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 130%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
    margin-left: 0px;
    margin-top: 110px;
  }
  .huesmerch_title {
    display: none;
  }
  .huesmerch_sub {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-left: 0px;
    color: var(--text);
  }
  .huesmerch_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 35px;
    margin-left: 0px;
    color: var(--text);
    text-align: justify;
  }
  .huesmerch_img {
    width: 355x;
    height: 355px;
    margin-top: 20px;
  }
  .btn-buymerch {
    width: 188px;
    height: 40px;
    background: var(--pink);
    border-radius: 4px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #ffffff;
    margin-left: 0px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .huesmerch_btns {
    text-align: center;
  }
}
@media (max-width: 768px) {
  .huesmerch_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    margin-left: 0px;
    color: var(--text);
    text-align: justify;
  }
  .huesmerch_img {
    width: 355x;
    height: 355px;
  }
  .huesmerch_title1 {
    display: block;
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 100%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
    margin-left: 0px;
    margin-top: 110px;
  }
  .huesmerch_title {
    display: none;
  }
  .prod_headings {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 30px;
  }
  .prod_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: var(--text);
    text-align: justify;
  }
  .prod_div {
    margin: 0px;
  }
  .products_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 60px;
    margin-bottom: 30px;
  }
}
@media (max-width: 600px) {
  .huesmerch_img {
    width: 300px;
    height: 300px;
  }
  .huesmerch_title1 {
    display: block;
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 100%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
    margin-left: 0px;
    margin-top: 110px;
  }
  .huesmerch_title {
    display: none;
  }
  .prod_headings {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 30px;
  }
  .prod_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: var(--text);
    text-align: justify;
  }
  .products_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 60px;
    margin-bottom: 30px;
  }
}
@media (max-width: 400px) {
  .prod_div {
    margin: 0px;
  }
  .prod_headings {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 20px;
  }
  .prod_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: var(--text);
    text-align: justify;
  }
}
@media (max-width: 382px) {
  .huesmerch_desc {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-left: 0px;
    color: var(--text);
    text-align: justify;
  }
  .huesmerch_img {
    width: 250px;
    height: 250px;
  }
  .huesmerch_title1 {
    display: block;
    font-family: Rubik;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 90%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
    margin-left: 0px;
    margin-top: 110px;
  }
  .huesmerch_title {
    display: none;
  }
  .products_title {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: var(--text);
    margin-top: 60px;
    margin-bottom: 30px;
  }
}
.collogo {
  width: 150px !important;
  height: 150px;
}
.zurich {
  width: 200px !important;
}
@media (max-width: 400px) {
  .collogo {
    width: 100px !important;
    height: 100px;
  }
  .zurich {
    width: 80px !important;
  }
}
.forms-div {
  margin-top: 120px;
}
@media (max-width: 640px) {
  .forms-height {
    height: 1750px;
  }
}
@media (max-width: 400px) {
  .forms-col {
    height: 1650px;
  }
}
