.carousel.carousel-slider {
    overflow: inherit;
}

.carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover{
    background-color: transparent;
    opacity: 0.6;
    right: -50px;
}

.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover {
    background-color: transparent;
    opacity: 0.6;
    left: -50px;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
    opacity: 1;
}

.carousel .control-next.control-arrow:before {
    content: '';
    border: solid #002242;
    opacity: 0.25;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 8px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
    content: '';
    border: solid #002242;
    opacity: 0.25;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 8px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
@media (max-width: 768px) {
    .carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover{
        background-color: transparent;
        right: -40px;
        opacity: 0.6;
    }
    
    .carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover {
        background-color: transparent;
        left: -40px;
        opacity: 0.6;
    }   
}
@media (max-width: 600px) {
    .carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover{
        background-color: transparent;
        right: -20px;
        opacity: 0.6;
    }
    
    .carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover {
        background-color: transparent;
        left: -20px;
        opacity: 0.6;
    }
    .carousel .control-next.control-arrow:before {
        content: '';
        border: solid #002242;
        opacity: 0.25;
        border-width: 0 4px 4px 0;
        display: inline-block;
        padding: 6px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    
    .carousel .control-prev.control-arrow:before {
        content: '';
        border: solid #002242;
        opacity: 0.25;
        border-width: 0 4px 4px 0;
        display: inline-block;
        padding: 6px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }   
}