@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
/*==========================================
LANDING PAGE HEADER
============================================*/

.landing_div {
  padding-top: 48px;
}
.landbg {
  background-image: url("../../../../images/landbg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.silver_award {
  font-family: Nunito;
  font-weight: 600;
  font-size: 26px;
  line-height: 38px;
  text-align: center;
  color: var(--pink);
}
.landing_title {
  font-family: Nunito;
  font-weight: 900;
  font-size: 64px;
  line-height: 130%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--text);
  margin-left: 100px;
}
.landing_subtitle {
  font-family: Nunito;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  color: var(--text);
  margin-left: 100px;
  margin-top: 30px;
}
.btn-huesofyou {
  font-family: Nunito;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.04em;
  color: var(--white);
  background: var(--pink);
  border-radius: 4px;
  margin-left: 100px;
  width: 188px;
  height: 40px;
  margin-top: 20px;
}
.btn-huesofyou:hover {
  color: var(--white);
  background-color: var(--hover-pink);
}
.btn-volunteerland {
  font-family: Nunito;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.04em;
  color: var(--pink);
  border: 1.5px solid var(--pink);
  box-sizing: border-box;
  border-radius: 4px;
  width: 188px;
  height: 40px;
  margin-left: 28px;
  margin-top: 20px;
}
.btn-volunteerland:hover {
  color: var(--hover-pink);
  border: 1.5px solid var(--hover-pink);
}

@media (max-width: 991px) {
  .landing_title {
    margin-left: 0px;
    text-align: center;
  }
  .landing_subtitle {
    margin-left: 0px;
    text-align: center;
  }
  .btn-volunteerland {
    display: none;
  }
  .btn-huesofyou {
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.04em;
    color: var(--white);
    background: var(--pink);
    border-radius: 4px;
    margin-left: 0px;
    width: 188px;
    height: 40px;
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .landing_title {
    font-weight: 900;
    font-size: 54px;
    line-height: 130%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
  }
  .landing_subtitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    color: var(--text);
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .buy_book {
    text-align: center;
  }
}
@media (max-width: 768px) {
  .landing_title {
    font-weight: 900;
    font-size: 48px;
    line-height: 130%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--text);
  }
  .landing_subtitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    color: var(--text);
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .land_img {
    width: 350px;
    height: 430px;
  }
}
@media (max-width: 600px) {
  .landing_title {
    font-weight: 900;
    font-size: 28px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.05em;
  }
  .landing_subtitle {
    font-size: 16px;
    line-height: 25px;
    text-align: center;
  }
  .land_img {
    width: 330px;
    height: 429px;
  }
}
@media (max-width: 382px) {
  .land_img {
    width: 260px;
    height: 338px;
  }
}
/*===============================================
AROUND THE WORLD
=================================================
*/

.facts_div {
  background-color: var(--darkblue);
  text-align: center;
  padding-bottom: 20px;
}
.p1 {
  font-family: "Nunito";
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--white);
  margin-bottom: 50px;
}
.heading_facts {
  font-family: "Rubik";
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--white);
  padding-top: 90px;
  margin-bottom: 20px;
}
.sub_heading_facts {
  font-family: "Nunito";
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: var(--white);
}
.heading3 {
  font-family: "Nunito";
  text-align: "center";
  color: var(--white);
  font-family: Nunito;
  font-weight: bold;
  font-size: 44px;
  padding-top: 30px;
}
.facts_source {
  font-family: Nunito;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--white);
  margin-right: 50px;
  margin-bottom: 90px;
}

/*============================================
HOW DO WE HELP
==============================================
*/

.helpbg {
  background-image: url("../../../../images/helpbg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.services_title {
  font-family: Rubik;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  height: 47px;
  color: var(--text);
  margin-bottom: 50px;
  margin-top: 90px;
}
.help1 {
  text-align: center;
}
.help2 {
  text-align: center;
  margin-bottom: 20px;
  padding-right: 40px;
}
.img-left {
  margin-left: 147px;
}
.titles .img-left {
  float: left;
  margin-right: 10px;
}
.titles .img-left1 {
  float: left;
  margin-right: 10px;
}
.headings1 {
  font-family: Nunito;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: var(--text);
  padding-top: 7px;
}
.para1 {
  font-family: Nunito;
  font-size: 17px;
  line-height: 25px;
  color: var(--text);
  margin-top: 13px;
  text-align: justify;
  text-justify: inter-word;
  margin-right: 147px;
}
.para2 {
  font-family: Nunito;
  font-size: 17px;
  line-height: 25px;
  color: var(--text);
  margin-top: 13px;
  text-align: justify;
  text-justify: inter-word;
  margin-left: 147px;
}
.know_more {
  font-family: Nunito;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--pink);
  text-decoration: none;
}
.know_more1 {
  font-family: Nunito;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--pink);
  text-decoration: none;
  margin-left: 147px;
}
.impact_div {
  position: relative;
  background-color: var(--darkblue);
  height: auto;
  width: 100%;
  left: 0px;
  border-radius: 0px;
  text-align: center;
  padding-bottom: 90px;
}
.heading_impact {
  font-family: Rubik;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--white);
  padding-top: 90px;
}
.sub_heading_impact {
  font-family: Nunito;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: var(--white);
}
.impact_headings {
  font-family: Nunito;
  font-weight: bold;
  font-size: 44px;
  line-height: 60px;
  text-align: center;
  color: var(--white);
}
.impact_subheadings {
  font-family: Nunito;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--white);
}
/*================================================
Collaborators 
==================================================*/
.collab_div {
  padding-bottom: 90px;
}
.heading_collab {
  font-family: Rubik;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  padding-top: 90px;
  padding-bottom: 30px;
}
.collaborators {
  padding-top: 60px;
  padding-bottom: 60px;
}

/*===========================================================
Our Hues
===========================================================----*/
.hues_div {
  background-color: var(--darkblue);
  width: 100%;
  padding-bottom: 90px;
}
.hues_title {
  padding-top: 90px;
  font-family: Rubik;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--white);
  margin-bottom: 48px;
}
.carousel_div {
  background-color: var(--skin);
}
.card-text-hues {
  font-family: Nunito;
  font-size: 18px;
  line-height: 25px;
  color: var(--text);
  margin-top: 20px;
  text-align: left;
}
.card-text-hues1 {
  font-family: Nunito;
  font-size: 18px;
  line-height: 25px;
  color: var(--text);
  text-align: left;
}
.card-text-hues2 {
  font-family: Nunito;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: var(--text);
  text-align: left;
}
.card-text-hues3 {
  font-family: Nunito;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: var(--text);
  text-align: left;
}
.card-img-hues {
  width: 400px;
}
.hues_outer {
  margin-bottom: 60px;
}
/*===========================================================
How can you help 
===========================================================--*/
.yourbg {
  background-image: url("../../../../images/yourhelpbg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.yourhelp_title {
  font-family: Rubik;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  height: 47px;
  color: var(--text);
  margin-bottom: 50px;
  margin-top: 50px;
}
.yourhelp1 {
  text-align: center;
}
.yourhelp2 {
  text-align: center;
  margin-bottom: 20px;
}
.titles .img-left {
  float: left;
  margin-right: 10px;
}
.yourheadings1 {
  font-family: Nunito;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: var(--text);
  padding-top: 7px;
}
.yourpara {
  font-family: Nunito;
  font-size: 17px;
  line-height: 25px;
  color: var(--text);
  margin-top: 13px;
  margin-right: 147px;
  text-align: justify;
  text-justify: inter-word;
}
.yourpara1 {
  font-family: Nunito;
  font-size: 17px;
  line-height: 25px;
  color: var(--text);
  margin-top: 13px;
  margin-left: 147px;
  text-align: justify;
  text-justify: inter-word;
}
.yourknow_more {
  font-family: Nunito;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--pink);
  text-decoration: none;
}
.yourknow_more1 {
  font-family: Nunito;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: var(--pink);
  text-decoration: none;
  margin-left: 147px;
}
.donate {
  margin-top: 35px;
  margin-bottom: 90px;
}
.donate_title {
  font-family: "Nunito";
  font-weight: 600;
  font-size: 30px;
  line-height: 25px;
  color: var(--text);
}

.done_desc {
  font-family: Nunito;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--text);
  margin-top: 35px;
}
.btn-donate {
  width: 166px;
  height: 40px;
  border: 1.5px solid var(--pink);
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Nunito;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.04em;
  color: var(--pink);
  margin-top: 24px;
}
.btn-donate:hover {
  color: var(--hover-pink);
  border: 1.5px solid var(--hover-pink);
}
/*-============================================================
Contact Us
============================================================---*/
.contactus_div {
  padding-bottom: 90px;
  background-image: url("../../../../images/contactusbg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.contactus_title {
  font-family: Rubik;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--text);
  margin-top: 90px;
  margin-bottom: 90px;
}
.contactus_heading {
  font-family: Nunito;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: var(--text);
}
.contactus_desc {
  font-family: Nunito;
  font-size: 18px;
  line-height: 25px;
  color: var(--text);
}
.contactus_email {
  font-family: Nunito;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: var(--text);
}
.contactus_follow {
  font-family: Nunito;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: var(--text);
}
.social_media_h {
  width: 35px !important;
  height: 35px !important;
  margin-right: 10px;
}
.social_media_con {
  width: 35px !important;
  height: 35px !important;
  margin-right: 8px;
}
.wrapper {
  position: relative;
}
i.fa-size {
  position: absolute;
  float: right;
  margin-right: 16px;
  margin-top: -28px;
  position: relative;
  z-index: 2;
  color: var(--text);
}
.btn-send {
  background: var(--pink);
  border: 1px solid var(--pink);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  color: var(--white);
  font-family: Nunito;
  margin-top: 20px;
}
.btn-send:hover {
  background-color: var(--hover-pink);
  color: var(--white);
}
.btn-send1 {
  background: var(--pink);
  border: 1px solid var(--pink);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  color: var(--white);
  font-family: Nunito;
  margin-top: 0px;
}
.btn-send1:hover {
  background-color: var(--hover-pink);
  color: var(--white);
}
.subscribe_label1 {
  font-family: Nunito;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--text);
  opacity: 0.8;
  margin-top: 10px;
}
.fci {
  margin-top: 10px;
}
.contactus_social_media {
  padding-bottom: 20px;
}
.subscribe_label {
  font-family: Nunito;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: var(--text);
  opacity: 0.8;
}