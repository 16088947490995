.top-content .carousel-control-prev-icon {
    position: absolute;
    width: 12px;
    height: 27px;
    background-image: url("../../images/prev.png");
    left: -30px;
    top: 250px;
}
 
.top-content .carousel-control-next-icon {
    position: absolute;
    width: 12px;
    height: 27px;
    background-image: url("../../images/next.png");
    right: -30px; 
    top: 250px; 
}
.top-content .carousel-indicators li {
    width: 9px;
    height: 9px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: black;
    border-radius: 50%;
}