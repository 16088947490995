.arrow {
    position: absolute;
    top: 35%;
  }
  
  .right {
    right: 0;
  }  
  .left {
    left: 0;
  }
  